.newservices {
  // text-align: center;
  width: 80%;
  // padding: 50px;
  // margin-top: 20px;
  // margin-bottom: 20px;

  h2 {
    font-weight: 700;
  }
  p {
    font-weight: 500;
  }
  // .divider {
  //   margin-right: auto;
  //   margin-left: auto;
  //   margin-bottom: 30px;
  //   width: 30%;
  //   height: 1px;
  //   background-color: var(--gray-color);
  //   filter: blur(3px);
  // }
  @media screen and (max-width: 1400px) {
    width: 90%;
  }
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
}
.newservices__section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px; /* Space between cards */

  @media screen and (max-width: 900px) {
  }
}

.newservices__section-service {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 30%;
  // margin: 1rem;
  // text-align: right;
  background-color: var(--primary-color);
  color: var(--white-color);
  padding-bottom: 12px;
  height: 450px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  // width: 358px;
  .image {
    border-radius: 5px;
    width: 100%;
    height: 225px;
    // margin-bottom: 10px;
    border: 5px solid var(--secondary-color);
    border-top: 1px solid var(--gray-color);
    border-right: 1px solid var(--gray-color);
    border-left: 1px solid var(--gray-color);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    width: 100%;
    padding: 15px;
    h4 {
      font-weight: 700;
      text-transform: uppercase;
      // text-align: right;
      color: var(--secondary-color);
      margin-bottom: 10px;
      word-wrap: break-word;
    }
    p {
      font-weight: 400;
      // text-align: right;
      font-size: 16px;
      margin-bottom: 15px;
      overflow: hidden;
      height: 70px;
      word-wrap: break-word;
    }
    .learn-more-btn {
      width: 35%;
      padding: 0.5rem;
      border: 2px solid var(--secondary-color);
      background-color: var(--secondary-color);
      text-align: center;
      transition: all 0.3s ease;
      a {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        color: var(--primary-color);
        text-decoration: none;
        font-size: 15px;
      }
      &:hover {
        background-color: transparent;
        cursor: pointer;
        a {
          color: var(--white-color);
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    width: 40%;
    .content {
      h4 {
      }
      p {
      }
    }
  }
  @media screen and (max-width: 1080px) {
    width: 60%;
  }
  @media screen and (max-width: 800px) {
    height: auto;
    width: 100%;
    .content {
      h4 {
      }
      p {
        font-size: 15px;
      }
    }
  }
}
