/*  الخلفية */
.bg-container {
  position: relative;
  width: 100%;
  height: 20vh; 
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
/* صورة الخلفية */
.bg-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; 
  background-image: url('https://images.pexels.com/photos/262347/pexels-photo-262347.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover;
  background-position: center;
  filter: brightness(0.4);
  z-index: 0;
}
/* المحتوى الرئيسي */
.main-content {
  bottom: 90%;
  position: absolute;
  right: 25%;
  top: 25%;
  text-align: center;
  color: white;
  padding: 20px;
  width: 50%;
}
/* مركز العناصر */
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5%;
  width: 100%;
  top: 20%;
  position: relative;
}
/* محتوى الصورة */
.image-container {
  text-align: center;
}
/* صورة */
.image-container img {
  width: 70%;
  height: 70vh;
  z-index: 2;
}
/* تنسيق للشاشات الصغيرة */
@media (max-width: 991px) {
  .bg-container {
      height: 30vh;
  }

  .main-content {
      width: 80%;
      right: 10%;
  }

  iframe {
      width: 100% !important;
      height: 300px !important;
  }
}
/* تنسيق للشاشات الضيقة */
@media (max-width: 767px) {
  .bg-container {
      height: 40vh;
  }

  .main-content {
      width: 90%;
      right: 5%;
  }

  iframe {
      width: 100% !important;
      height: 250px !important;
  }
}


.rtl {
  direction: rtl;
  text-align: right;
}

.ltr {
  direction: ltr;
  text-align: left;
}
