@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

:root {
  --font-base: "Cairo", sans-serif !important;

  --primary-color: #13233b;
  --secondary-color: #f8be2e;
  --black-color: #030303;
  --gray-color: #333333;
  --lightgray-color: #f6f6f6;
  --white-color: #ffffff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  width: 100% !important;
  direction: rtl;
  font-family: "Cairo", sans-serif !important;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
.m7mdcontainerm7md {
  margin-top: 40px;
  margin-bottom: 40px;
  // padding-top: 15px;
  // padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  // margin: 10px auto;
  // display: flex;
  // flex-direction: column;
  // width: 90%;

  // justify-content: center;
  // align-items: center;

  @media screen and (min-width: 1200px) {
    width: 1170px;
  }
  @media screen and (min-width: 992px) {
    width: 970px;
  }
  @media screen and (min-width: 768px) {
    width: 750px;
  }
}
.section__title {
  padding-top: 20px;
  text-align: center;
  .divider {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    width: 50px;
    // padding: 0 1%;
    height: 1px;
    background-color: var(--gray-color);
    filter: blur(2px);
  }
}
.page__title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  padding-top: 50px;
  text-align: center;
  h2 {
    font-size: 62px;
    font-weight: 700;
  }
  p{
    font-size: 28px;
    font-weight: 500;
  }
  @media screen and (max-width: 992px) {
    h2 {
      font-size: 52px;
      font-weight: 700;
    }
    p{
      font-size: 21px;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 668px) {
    h2 {
      font-size: 42px;
      font-weight: 700;
    }
    p{
      font-size: 18px;
      font-weight: 500;
    }
  }
  .divider {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    width: 80%;
    // padding: 0 1%;
    height: 1px;
    background-color: var(--secondary-color);
    filter: blur(1px);
  }
}

// .portfolio-image-container {
//   height: 350px;
//   margin-bottom: 30px;
// }
// .portfolio-image-container img {
//   object-fit: cover;
//   width: 100%;
//   height: 100%;
// }
.container-padding {
  padding: 30px;
}
