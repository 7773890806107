// .new__overlay-content {
//   background-color: rgba(51, 51, 51, 0.404);
//   padding: 12rem 11rem;
//   height: 100vh;
//   text-align: right;
//   display: flex;
//   flex-direction: column;
//   gap: 30px;
//   h1 {
//     font-weight: 700;
//     color: var(--primary-color);
//     line-height: 75px;
//     font-size: 48px;

//     @media screen and (max-width: 800px) {
//       font-size: 44px;
//       line-height: 62px;
//     }
//     @media screen and (max-width: 450px) {
//       font-size: 36px;
//       line-height: 48px;
//     }
//   }
//   p {
//     color: var(--white-color);
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 38px;

//     @media screen and (max-width: 800px) {
//       font-size: 20px;
//       line-height: 32px;
//     }
//     @media screen and (max-width: 450px) {
//       font-size: 18px;
//       line-height: 26px;
//     }
//   }

//   @media screen and (max-width: 800px) {
//     padding: 12rem 2rem;
//   }
// }

// .contact-btns {
//   margin-top: 1rem;

//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   a {
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     width: 194px;
//     height: 62px;
//     font-weight: 700;
//     font-size: 20px;
//     text-align: center;
//     margin-top: 0.5rem;
//     text-decoration: none;
//     color: var(--white-color);
//     background-color: var(--primary-color);
//     border-radius: 9px;
//     border: 2px solid var(--primary-color);
//     transition: all 0.3s ease;
//     &:hover {
//       background-color: transparent;
//       color: var(--secondary-color);
//     }
//   }
//   // a:last-of-type {
//   //   margin-right: 2rem;
//   //   background-color: transparent;
//   //   color: var(--white-color);
//   //   &:hover {
//   //     background-color: var(--primary-color);
//   //     color: var(--secondary-color);
//   //   }
//   // }
//   @media screen and (max-width: 800px) {
//     flex-direction: column;
//     a {
//       width: 170px;
//       height: 62px;
//       font-size: 19px;
//     }
//     // a:last-of-type {
//     //   margin-right: 0;
//     // }
//   }
//   @media screen and (max-width: 450px) {
//     a {
//       width: 145px;
//       height: 45px;
//       font-size: 19px;
//     }
//   }
// }

.slider-container.newhero {
  // position: relative;
  width: 100%;
  // max-width: 1400px;
  // width: 100vw;
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
  @media screen and (max-width: 900px) {
    height: 460px;
  }
  @media screen and (max-width: 800px) {
    height: 450px;
    // padding-bottom: 90px;
  }
  @media screen and (max-width: 700px) {
    height: 430px;
    // padding-bottom: 90px;
  }
  @media screen and (max-width: 600px) {
    height: 400px;
    // padding-bottom: 90px;
  }
  @media screen and (max-width: 480px) {
    height: 300px;
    // padding-bottom: 90px;
  }
}
.slick-prev {
  left: 3% !important;
  z-index: 1;
  &::before {
    content: "";
  }
}
.slick-next {
  right: 3% !important;
  z-index: 1;
  &::before {
    content: "";
  }
}
.slider-slide {
  // position: relative;
  width: 100%;
  height: 100vh;
  /* Adjust height as needed */
  background-size: cover;
  background-position: center;
}

.slider-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.slider-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 20px;
}

.slider-overlay h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.slider-overlay p {
  font-size: 18px;
  margin-bottom: 20px;
  max-width: 600px;
}

.slider-buttons {
  display: flex;
  gap: 10px;
}

.slider-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
}

.slider-button {
  padding: 10px 20px;
  border: 1px solid var(--secondary-color);
  text-align: center;
  transition: all 0.3s ease;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    cursor: pointer;
    // color: var(--white-color);
  }
}

.slider-button.primary {
  background-color: var(--primary-color); /* Change to your primary color */
  &:hover {
    background-color: transparent;
    cursor: pointer;
    color: var(--white-color);
  }
}

.slider-button.secondary {
  background-color: var(--secondary-color); /* Change to your secondary color */
  color: var(--primary-color);
  &:hover {
    background-color: transparent;
    cursor: pointer;
    color: var(--white-color);
  }
}

.slick-prev,
.slick-next {
  z-index: 1;
  font-size: 24px;
  color: #fff;
}

.slick-prev:before,
.slick-next:before {
  color: #fff;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 768px) {
  .slider-slide {
    height: 400px; /* Adjust height for tablet */
  }

  .slider-overlay h2 {
    font-size: 28px; /* Adjust font size for tablet */
  }

  .slider-overlay p {
    font-size: 16px; /* Adjust font size for tablet */
    max-width: 90%;
  }

  .slider-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .slider-button {
    padding: 8px 16px;
  }
}

@media screen and (max-width: 480px) {
  .slider-slide {
    height: 300px; /* Adjust height for mobile */
  }

  .slider-overlay h2 {
    font-size: 24px; /* Adjust font size for mobile */
  }

  .slider-overlay p {
    font-size: 14px; /* Adjust font size for mobile */
    max-width: 90%;
  }

  .slider-buttons {
    flex-direction: column;
    gap: 5px;
  }

  .slider-button {
    padding: 6px 12px;
  }
}
