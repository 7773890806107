.frequentquestions {
  margin: 40px auto;
  width: 90vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row-reverse;
  // height: 700px;
  // height: 600px;

  // @media screen and (min-width: 1200px) {
  //   width: 1170px;
  // }
  // @media screen and (min-width: 992px) {
  //   width: 970px;
  // }
  // @media screen and (min-width: 768px) {
  //   width: 750px;
  // }
  @media screen and (max-width: 968px) {
    flex-direction: column;
  }
}
.frequentquestions-image {
  width: 45%;
  height: 500px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 968px) {
    width: 100%;
  }
}
.frequentquestions-content {

  width: 45%;
  h2 {
    font-weight: 700;
    font-size: 22px;
  }
  .accordion {
    width: 100%;
    // @media screen and (max-width: 1050px) {
    //   width: 70%;
    // }
    // @media screen and (max-width: 700px) {
    //   width: 90%;
    // }
  }
  .accordion-item {
    border: 1px solid var(--gray-color);
    background-color: var(--primary-color);
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 0.1rem;
    .title {
      h2 {
        font-size: 18px;
        font-weight: 700;
      }
      background-color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: var(--white-color);

      cursor: pointer;
      // h2 {
      //   font-weight: 700;
      //   font-size: 36px;
      //   line-height: 56px;
      // }
    }
    .content {
      font-weight: 700;
      font-size: 15px;
      line-height: 38px;
      color: var(--secondary-color);
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s cubic-bezier(0, 1, 0, 1);
      &.show {
        height: auto;
        max-height: 9999px;
        transition: all 0.5s cubic-bezier(1, 0, 1, 0);
      }
    }
  }
  @media screen and (max-width: 968px) {
    width: 100%;
  }
}
