.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 0;
}

.services .images {
  margin-top: 100vh;
  height: auto;
  margin: 0 auto;
}

/* body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white !important;
} */

.card {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 10px 0;
}

.card-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.card-image:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(233, 145, 14, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image:hover .overlay {
  opacity: 1;
}

.icon {
  font-size: 50px;
  color: black;
}

.carousel .slide img {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

@media (max-width: 992px) {
  .card {
    width: 100%;
    padding-bottom: 56.25%;
  }
  .card-image img {
    height: auto;
  }

  .carousel .slide img {
    max-height: 100vh;
  }
}

@media (max-width: 768px) {
  .services-title {
    font-size: 20px;
  }

  .services-description {
    font-size: 14px;
  }

  .card {
    width: 100%;
    padding-bottom: 70%;
  }

  .card-image img {
    height: 100%;
    object-fit: cover;
  }

  .carousel .slide img {
    height: 200vh;
  }

  .modal-dialog {
    width: 100%;
    margin: auto;
  }
}

.modal-backdrop-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1040;
}

.gallery-container1 {
  display: flex;
  justify-content: center;
}

.newmodal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.carousel-control-next,
.carousel-control-prev
{
  color: #fff !important;
}
