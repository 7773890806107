/* .floating-form-container {
  position: fixed;
  bottom: 20px;
  z-index: 1000;
}

.floating-form-container.ltr {
  right: 20px;
}

.floating-form-container.rtl {
  left: 20px;
}

.floating-form-button {
  background-color: #28a745; 
  color: #fff; 
  border: none; 
  border-radius: 5px; 
  padding: 10px 20px; 
  cursor: pointer; 
}

.floating-form {
  position: fixed; 
  bottom: 70px; 
  background-color: #fff; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  padding: 20px; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
  z-index: 1002;
}

.floating-form.ltr {
  right: 20px;
}

.floating-form.rtl {
  left: 20px;
}

.close-form-button {
  background-color: #dc3545; 
  color: #fff; 
  border: none;
  border-radius: 5px; 
  padding: 5px 10px;
  margin-top: 10px; 
  cursor: pointer; 
}

.floating-form input,
.floating-form textarea {
  width: 100%; 
  margin-bottom: 10px; 
  padding: 10px; 
  border: 1px solid #ccc;
  border-radius: 5px; 
}

.floating-form button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.floating-form button[type="submit"]:hover {
  background-color: #0056b3;
} */

.whatsapp-btn {
  cursor: pointer;
  z-index: 999;
  width: 60px;
  height: 60px;
  position: fixed;
  background-color: #00e676;
  bottom: 30px;
  left: 30px;
  border-radius: 50%;
  border: 3px solid var(--white-color);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s ease;
  svg {
    width: 35px;
    height: 35px;
    color: var(--white-color);
  }
  &:hover {
    transform: scale(1.1);
  }
}
