// NewTeamMembers.scss
.new-team-members {
  text-align: center;

  .team-header {
    background-color: #282c34;
    padding: 20px;
    color: white;

    h1 {
      margin: 0;
    }
  }

  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .single-card {
    position: relative;
    width: 200px;
    margin: 20px;
    overflow: hidden;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-10px);

      .social-icons {
        display: block;
      }
    }

    .card-image {
      position: relative;

      img {
        width: 100%;
        height: auto;
      }

      .social-icons {
        position: absolute;
        bottom: 10px;
        left: 10px;
        display: none;

        a {
          margin: 0 5px;
          color: #fff;
          background-color: #000;
          padding: 5px;
          border-radius: 50%;
          text-decoration: none;

          i {
            font-size: 16px;
          }
        }
      }
    }

    .card-content {
      padding: 10px;
      text-align: center;
      background-color: #001f3f; /* Change to your preferred background color */
      color: #fff; /* Change to your preferred text color */

      h3 {
        margin: 0;
        font-size: 18px;
      }

      p {
        margin: 5px 0 0;
        font-size: 14px;
      }
    }
  }
}
