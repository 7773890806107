/* تنسيق navbar عند الانتقال للأعلى */
.nav1 {
  height: 56px;
  padding: 40px;
  width: 100%;
  background-color: var(--primary-color);
  z-index: 1050;
  transition: background-color 0.3s;
  padding-left: 20px; /* إضافة padding من اليسار */
  padding-right: 20px; /* إضافة padding من اليمين */
}

/* إخفاء nav1 على الشاشات الصغيرة والمتوسطة */
@media (max-width: 991.98px) {
  // .nav1 {
  //   display: none;
  // }
}

// /* navbar عند الانتقال للأسفل */
// .nav2 {
//   height: 10vh;
//   z-index: 1050;
//   width: 100%;
//   padding: 1.5rem 2rem; /* تعديل الحواف هنا */
//   background-color: white;
//   position: sticky;
//   top: 0;
//   transition: background-color 0.3s;
//   padding-left: 20px; /* إضافة padding من اليسار */
//   padding-right: 20px; /* إضافة padding من اليمين */
// }

// /* logo */
// .logo img {
//   height: 25px;
// }

// /* روابط navbar */
// .nav-link {
//   color: #13233C !important;
//   transition: color 0.3s, background-color 0.3s;
// }

// /* تنسيق روابط navbar عند الوقوف عليها */
// .nav-link:hover {
//   color: #FFC107 !important;
// }

// /* تنسيق navbar عند تغيير الحجم للون الأبيض */
// .expanded {
//   background-color: white !important;
//   width: 73vh;
// }

// /* تنسيق روابط navbar عند الانتقال للون الأبيض */
// .expanded .nav-link {
//   height: auto;
//   text-align: end;
//   padding: 10px;
//   margin: 0;
//   color: #13233C !important;
//   background-color: white !important;
//   display: block;
// }

// /* تنسيق أيقونات navbar عند الانتقال للون */
// .expanded .fa-solid {
//   background-color: white !important;
//   border-color: transparent;
//   box-shadow: transparent;
// }

// /* تعديل CSS للتأكد من أن القائمة تنغلق بشكل صحيح عند النقر على رابط */
// .navbar-collapse {
//   transition: height 0.3s;
// }

// .navbar-toggler {
//   border: none;
//   font-size: 1.25rem;
// }

// .navbar-toggler:focus {
//   outline: none;
// }

// .collapse.show {
//   display: block !important;
//   background-color: white !important;
//   text-align: end;
//   width: 69vh;
// }

// /* تنسيق الروابط في وضع expanded */
// .nav2.expanded .navbar-nav {
//   flex-direction: column;
//   align-items: flex-end;
//   width: 100%;
// }

// .nav2.expanded .nav-item {
//   width: 100%;
//   text-align: right;
// }

// /* وسائل الإعلام لتنسيق صغير ومتوسط */
// @media (max-width: 767.98px) {
//   /* المحتوى المنقول من nav1 لتعذيته في nav2 */
//   .navbar-text-content {
//     background-color: white;
//     padding: 10px;
//   }

//   .navbar-text-content .nav1-content {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;  /* لجعل أيقونات التليفون والإيميل تتحرك إلى اليسار */
//     background-color: white;
//     margin-bottom: 10px;
//   }

//   .navbar-icons {
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
//   }

//   .navbar-icons .nav-link {
//     flex: 1;
//     padding: 10px;
//     text-align: center;
//     background-color: white;

//   }

//   .nav-link i.fa-solid,
//   .nav-link i.fa-brands {
//     font-size: 1rem;

//   }

//   .dropdown-menu {
//     width: auto;
//     padding: 0;
//     margin: 0;
//   }

//   .dropdown-item {
//     display: flex;
//     align-items: center;
//   }

//   .dropdown-item img {
//     margin-right: 8px;
//   }
// }
// .dropdown-toggle {
//   background-color: transparent !important;
//   border: none !important;
//   box-shadow: none !important;
//   color: #fff; /* تغيير لون الأيقونة إذا لزم الأمر */
// }

// /* Adjust padding and margins for Dropdown Toggle */
// .dropdown-toggle {
//   background-color: transparent !important;
//   border: none !important;
//   box-shadow: none !important;
//   color: #fff; /* Change icon color if needed */
//   display: flex;
//   align-items: center;
// }

// .dropdown-toggle img {
//   margin-right: 8px;
// }

// .dropdown-toggle::after {
//   display: none; /* Hide the default arrow */
// }

// /* Custom arrow for dropdown */
// .dropdown-toggle::before {

//   font-size: 0.6em;
//   margin-left: 8px;
//   color: #fff; /* Change arrow color if needed */
//   vertical-align: middle; /* Aligns the arrow vertically with the image */
//   line-height: 1; /* Ensures no extra space above or below the arrow */
// }

// html[dir="rtl"] .dropdown-toggle img {
//   margin-left: 14px;
//   margin-right: 50px;
// }

// html[dir="rtl"] .dropdown-toggle::before {
//   margin-left: 0;
//   margin-right: 8px;
// }
.app__topnavbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary-color);
  padding-right: 5rem;
  padding-left: 5rem;
  height: 50px;

  @media screen and (max-width: 825px) {
    // padding-top: 1.5rem;
    // padding-bottom: 1.5rem;
    padding-left: 2.5rem;
    padding-left: 2.5rem;
  }
  @media screen and (max-width: 700px) {
    // padding-top: 1.5rem;
    // padding-bottom: 1.5rem;
    padding-right: 1.9rem;
    padding-left: 1.9rem;
  }
  @media screen and (max-width: 670px) {
    // padding-top: 1.5rem;
    // padding-bottom: 1.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
    flex-direction: column;
    height: auto;
  }
}
.app__topnavbar-translate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.app__topnavbaremail {
  @media screen and (max-width: 670px) {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
  }
}
.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white-color);
  padding-right: 5rem;
  padding-left: 5rem;
  height: 85px;
  // 550px
  @media screen and (max-width: 600px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 4rem;
    padding-left: 2.3rem;
  }
}
.app__navbar-logo {
  width: 60px;
  height: 60px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.9);
  }
}
.app__navbar-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .app__navbar-item {
    margin: 1rem 2rem;

    a {
      font-size: 16px;
      text-decoration: none;
      color: var(--primary-color);
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
      &.active {
        color: var(--secondary-color);
        transform: scale(1.3);
      }
    }
  }
  @media screen and (max-width: 1237px) {
    display: none;
  }
  .app__navbar-blog {
    margin: 0 2rem;
    font-size: 20px;
    text-decoration: none;
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: var(--secondary-color);
    }
  }
  .app__navbar-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;

    text-decoration: none;

    width: 215.62px;
    height: 47px;
    background: #ffffff;
    border-radius: 10px;
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: var(--secondary-color);
    }
  }
}
.app__navbar-menu {
  cursor: pointer;

  @media screen and (min-width: 1237px) {
    display: none;
  }
  //   &.active span:nth-child(2) {
  //     opacity: 0;
  //   }
  //   &.active span:nth-child(1) {
  //     transform: translateY(8px) rotate(45deg);
  //   }
  //   &.active span:nth-child(3) {
  //     transform: translateY(-8px) rotate(-45deg);
  //   }
  //   span {
  //     display: block;
  //     height: 3px;
  //     width: 25px;
  //     margin: 5px auto;
  //     -webkit-transition: all 0.3s ease-in-out;
  //     transition: all 0.3s ease-in-out;
  //     background-color: var(--black-color);
  //     &:nth-child(2) {
  //     }
  //   }
  svg {
    width: 25px;
    height: 25px;
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;

    &:hover {
      color: var(--secondary-color);
    }
  }
}

.app__navbar-menu-container {
  cursor: auto;
  z-index: 99;
  top: 0;
  left: 0;
  position: fixed;
  width: 80%;
  height: 100%;
  background-color: var(--primary-color);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 4rem 17rem;

  @media screen and (max-width: 450px) {
    padding-left: 2rem;
  }

  .app__navbar-menu-btn {
    cursor: pointer;
    padding-left: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    svg {
      width: 30px;
      height: 30px;
      color: var(--white-color);
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    // @media screen and (max-width: 800px) {
    //   padding-left: 20rem;
    // }
    // @media screen and (max-width: 550px) {
    //   padding-left: 15rem;
    // }
  }
}
.app__navbar-menu-container-right {
  cursor: auto;
  z-index: 99;
  top: 0;
  right: 0;
  position: fixed;
  width: 80%;
  height: 100%;
  background-color: var(--primary-color);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 4rem 17rem;

  @media screen and (max-width: 450px) {
    padding-left: 2rem;
  }

  .app__navbar-menu-btn {
    cursor: pointer;
    padding-left: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    svg {
      width: 30px;
      height: 30px;
      color: var(--white-color);
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    // @media screen and (max-width: 800px) {
    //   padding-left: 20rem;
    // }
    // @media screen and (max-width: 550px) {
    //   padding-left: 15rem;
    // }
  }
}
.app__navbar-menu-items {
  .app__navbar-menu-item {
    cursor: pointer;
    margin: 2.5rem;

    a {
      font-size: 22px;
      font-weight: 500;
      text-decoration: none;
      color: var(--white-color);
      transition: all 0.3s ease-in-out;
      &:hover {
        color: var(--secondary-color);
      }
      &.active {
        color: var(--secondary-color);
        font-size: 25px;
      }
    }
  }
  .app__navbar-blog {
    text-align: center;
    margin: 0 2.7rem;
    margin-bottom: 2rem;
    font-size: 22px;
    text-decoration: none;
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: var(--secondary-color);
    }
  }
  .app__navbar-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;

    text-decoration: none;

    margin-top: 2rem;
    width: 215.62px;
    height: 47px;
    background: #ffffff;
    border-radius: 10px;
    color: var(--primary-color);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: var(--secondary-color);
    }
  }
}
