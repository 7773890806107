/* Adjustments for RTL and LTR text alignment */
.rtl {
	direction: rtl;
	text-align: right;
  }
  
  .ltr {
	direction: ltr;
	text-align: left;
  }
  
  /* Adjustments for Read More Button */
  .read-more-btn {
	background: none;
	border: none;
	color: #f8be2e; /* Set button color */
	cursor: pointer;
	text-decoration: underline;
	font-size: 16px; /* Adjust font size */
  }
  
  /* Adjustments for Text Container */
  .text-container {
	max-height: 200px; /* Adjust the value as needed */
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3; /* Number of lines to show */
	-webkit-box-orient: vertical;
  }
  
  .text-container.expanded {
	max-height: none; /* Allow full height when expanded */
	-webkit-line-clamp: unset;
  }
  
  .text-container p {
	margin-bottom: 1em; /* Adjust the value as needed */
  }

	.portfolio-image-container {
		height: 350px;
		margin-bottom: 30px;
	}
	.portfolio-image-container img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
	.container-padding {
		padding: 30px;
	}
  